<template>
  <div>
    <b-card no-body>
      <div class="mx-2 mt-2 mb-50">
        <b-row>
          <b-col
            cols="12"
            md="8"
            sm="6"
          >
            <h4 class="mb-0">
              部門列表
            </h4>
          </b-col>

          <b-col
            cols="12"
            md="4"
            sm="6"
          >
            <div class="text-nowrap d-flex justify-content-end">
              <div
                v-b-modal.add-modal
                v-b-tooltip.hover.v-secondary
                title="新增"
                class="d-flex align-items-center actions-link-btn"
              >
                <b-img
                  src="/admin/images/table/plus.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="重新整理"
                class="d-flex align-items-center actions-link-btn ml-25"
                @click="refetchTable"
              >
                <b-img
                  src="/admin/images/table/refresh.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="列表顯示"
                class="d-flex align-items-center actions-link-btn ml-25"
              >
                <b-dropdown
                  variant="link"
                  toggle-class="p-0"
                  no-caret
                  right
                >
                  <template #button-content>
                    <b-img
                      src="/admin/images/table/setting.svg"
                      class="actions-link-btn-image"
                      rounded
                    />
                  </template>

                  <b-dropdown-form>
                    <b-form-group>
                      <label class="mb-50">
                        列表顯示
                      </label>

                      <b-form-checkbox
                        v-for="column in tableColumnsSearchable"
                        :key="column.key"
                        v-model="column.select"
                        name="table-column"
                        class="mb-1"
                      >
                        {{ column.label }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-dropdown-form>

                </b-dropdown>
              </div>
            </div>
          </b-col>

        </b-row>
      </div>

      <!-- 顯示個數/搜尋框 -->
      <div class="mx-2 mb-2">
        <b-row>
          <!-- 顯示個數 -->
          <b-col
            cols="12"
            md="6"
            sm="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-sm-0"
          >
            <!-- <label class="text-nowrap">顯示</label> -->
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="table-perPage-select"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  沒有<em>{{ search }}</em> 的相關結果
                </template>

                <div v-else>
                  <small>暫無資料</small>
                </div>
              </template>
            </v-select>
          </b-col>

          <!-- 搜尋框 -->
          <b-col
            cols="12"
            md="6"
            sm="8"
          >
            <!-- 搜尋框 -->
            <div class="w-100 mr-1">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQuery"
                  debounce="500"
                  class="d-inline-block"
                  placeholder="搜尋..."
                />
                <b-input-group-append
                  v-if="searchQuery"
                  is-text
                >
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer text-muted"
                    @click="searchQuery = null"
                  />
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>

        </b-row>
      </div>

      <!-- 列表 -->
      <b-table
        ref="refDataListTable"
        :items="getDepartmentListData"
        :fields="tableColumnsFilter"
        responsive
        striped
        hover
        primary-key="id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        show-empty
        :stacked="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        <!-- 忙碌中 -->
        <template #table-busy>
          <b-skeleton-table
            :rows="5"
            :columns="tableColumnsFilter.length"
            :table-props="{ bordered: true, striped: true }"
          />
        </template>

        <!-- 查無資料 -->
        <template #empty>
          <div class="text-center my-5 animate__animated animate__fadeIn">
            <b-img
              :src="$store.state.app.notFoundImg"
              fluid
              width="480"
              alt="查無資料"
            />
          </div>
        </template>

        <!-- 欄位: 編號 -->
        <template #cell(id)="data">
          <div class="table-col">
            <span>{{ data.item.id }}</span>
          </div>
        </template>

        <!-- 欄位: 部門名稱 -->
        <template #cell(name)="data">
          <b-link
            class="font-weight-bold d-block text-nowrap item-description"
            @click="onSubmitEdit(data.item)"
          >
            {{ data.item.name }}
          </b-link>
        </template>

        <!-- 欄位: 說明 -->
        <template #cell(description)="data">
          <div
            class="table-col item-description"
            @click="onSubmitShowDescription(data.item)"
          >
            <!-- v-b-tooltip.hover.focus.v-secondary
            :title="`${data.item.description ? data.item.description : ''}`" -->
            {{ data.item.description ? data.item.description : '---' }}
          </div>
        </template>

        <!-- 欄位: 員工數量 -->
        <template #cell(total)="data">
          <div class="table-title">
            {{ parseInt(data.item.total, 10).toLocaleString() }}
          </div>
        </template>

        <!-- 欄位: 新增時間 -->
        <template #cell(created_at)="data">
          <div style="min-width: 85px;">
            <div v-if="data.item.created_at">
              <span
                v-b-tooltip.hover.focus.v-secondary
                :title="`${moment(data.item.created_at).format('HH:mm')}`"
              >
                {{ updateOnline(data.item.created_at, refonlineTime) }}
              </span>
            </div>

            <div v-else>
              <span>---</span>
            </div>
          </div>
        </template>

        <!-- 欄位: 動作 -->
        <template #cell(actions)="data">
          <!-- ="data" -->
          <div class="d-flex mb-50">
            <div
              class="actions-link-btn mr-25"
              @click="onSubmitEdit(data.item)"
            >
              <b-img
                v-b-tooltip.hover.focus.v-secondary
                title="編輯"
                src="/admin/images/table/edit.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>

            <div
              class="actions-link-btn mr-25"
              @click="onSubmitDelete(data.item)"
            >
              <b-img
                v-b-tooltip.hover.focus.v-secondary
                title="刪除"
                src="/admin/images/table/delete.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>
            <!-- <b-button
              variant="flat-secondary"
              size="sm"
              class="text-nowrap"
              @click="onSubmitEditAuth(data.item)"
            >
              <feather-icon
                icon="LockIcon"
                class="mr-25"
              />
              <span class="align-middle">權限</span>
            </b-button>

            <b-button
              variant="flat-secondary"
              size="sm"
              class="text-nowrap"
              @click="onSubmitEdit(data.item)"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-25"
              />
              <span class="align-middle">編輯</span>
            </b-button>

            <b-button
              variant="flat-secondary"
              size="sm"
              class="text-nowrap"
              @click="onSubmitDelete(data.item)"
            >
              <feather-icon
                icon="Trash2Icon"
                class="mr-25"
              />
              <span class="align-middle">刪除</span>
            </b-button> -->

            <!-- <b-dropdown
              variant="link"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreHorizontalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item @click="onSubmitEditAuth(data.item)">
                <feather-icon icon="LockIcon" />
                <span class="align-middle ml-50">權限</span>
              </b-dropdown-item>

              <b-dropdown-item @click="onSubmitEdit(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">編輯</span>
              </b-dropdown-item>

              <b-dropdown-item @click="onSubmitDelete(data.item)">
                <feather-icon icon="Trash2Icon" />
                <span class="align-middle ml-50">刪除</span>
              </b-dropdown-item>

            </b-dropdown> -->
          </div>
        </template>
      </b-table>

      <!-- 顯示頁數/分頁 -->
      <div class="mx-2 mb-2">
        <b-row>
          <!-- 顯示頁數 -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">第 {{ dataMeta.from }} - {{ dataMeta.to }} 筆，共 {{ dataMeta.of }} 筆</span>
          </b-col>

          <!-- 分頁 -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalNum"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

    </b-card>

    <!-- 新增部門 -->
    <add-modal
      @refetch-data="refetchTable"
    />

    <!-- 編輯部門 -->
    <edit-modal
      v-if="selected"
      ref="editModal"
      :department-data-item="selected"
      @refetch-data="refetchTable"
    />

    <b-modal
      v-if="selected"
      id="description-modal"
      centered
      hide-footer
    >
      <template #modal-title>
        <h4 class="m-0">
          部門說明
        </h4>
      </template>

      <div>
        <div class="description-title text-primary">
          {{ selected.name }}
        </div>

        <div class="description-content">{{ selected.description }}
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
// API
import { onUnmounted } from '@vue/composition-api'
import moment from 'moment'

// UI
import {
  BCard, BRow, BTable, BCol, BPagination, VBTooltip, BDropdown, BImg, BLink,
  BDropdownForm, BFormInput, BInputGroupPrepend, BInputGroup, BFormGroup, BFormCheckbox, BSkeletonTable,
  BInputGroupAppend, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import router from '@/router'
import store from '@/store'

// Component
import { useDepartmentList, useDepartmentSetting } from '../useDepartment'
import AddModal from './DepartmentListAddModal.vue'
import EditModal from './DepartmentListEditModal.vue'
// import AuthModal from './DepartmentListAuthModal.vue'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import useStoreModule from '../useStoreModule'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BLink,
    BFormInput,
    BTable,
    BModal,
    BPagination,
    BDropdown,
    // BDropdownItem,
    BDropdownForm,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BFormCheckbox,
    BSkeletonTable,

    vSelect,
    AddModal,
    EditModal,
    // AuthModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  data() {
    return {
      selected: null,
      title: router.currentRoute.meta.pageTitle,
    }
  },
  computed: {
    tableColumnsFilter() {
      // if (this.authAbility.user !== 'write') {
      //   return this.tableColumns
      //     .filter(f => f.select && f.key !== 'actions')
      // }
      return this.tableColumns
        .filter(f => f.select)
    },
    tableColumnsSearchable() {
      // if (this.authAbility.user !== 'write') {
      //   return this.tableColumns
      //     .filter(f => f.searchable && f.key !== 'actions')
      // }
      return this.tableColumns
        .filter(f => f.searchable)
    },
    authAbility() {
      return this.$store.state.app.ability
    },
  },
  mounted() {
    this.timer = setInterval(this.onlineTime, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    moment,
    // (觸發)顯示部門說明
    onSubmitShowDescription(item) {
      if (!item.description) return
      this.selected = item
      // this.useSwalAlertInfomation(item.name, item.description)
      setTimeout(() => { this.$bvModal.show('description-modal') }, 200)
    },

    // (觸發)編輯部門
    onSubmitEdit(item) {
      // if (this.authAbility.user !== 'write') return
      this.selected = item
      setTimeout(() => { this.$refs.editModal.getData() }, 200)
    },

    // (刪除)管理員
    onSubmitDelete(item) {
      // if (this.authAbility.user !== 'write') return
      this.useSwalAlertWarning('刪除部門', `你確定要永久刪除部門 ${item.name} 嗎?`)
        .then(result => {
          if (result.value) {
            this.setDepartmentDelete({
              department_id: item.id,
            }).then(() => {
              this.refetchData()
              this.useSwalAlertCenter(true, '刪除成功!', '部門已移除')
            })
              .catch(error => {
                this.useSwalAlertCenter(false, '刪除失敗!', error.response.data.message)
              })
          }
        })
    },
  },
  setup() {
    // 註冊模組
    const DEPARTMENT_ADMIN_STORE_MODULE_NAME = 'admin-department'

    if (!store.hasModule(DEPARTMENT_ADMIN_STORE_MODULE_NAME)) store.registerModule(DEPARTMENT_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(DEPARTMENT_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(DEPARTMENT_ADMIN_STORE_MODULE_NAME)
    })

    const {
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchData,
      refetchTable,
      timeRange,

      setDepartmentDelete,
      getDepartmentListData,
    } = useDepartmentList()

    const {
      ui,
      statusOptions,
      refonlineTime,
      onlineTime,
      updateOnline,
    } = useDepartmentSetting()

    const {
      useAlertToast,
    } = useAlert()

    return {
      ui,
      useAlertToast,
      statusOptions,
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchData,
      refetchTable,
      timeRange,

      setDepartmentDelete,
      getDepartmentListData,
      refonlineTime,
      onlineTime,
      updateOnline,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.item-description {
  // background-color: red;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
  // font-size: 1.4rem;
}

.description-title {
  font-size: 18px;
  margin-bottom: 8px;
}

.description-content {
  white-space: pre-wrap;
}
</style>
