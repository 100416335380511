import { ref, watch } from '@vue/composition-api'
import {
  useTableComponent, useCommenSettings, useAlert,
} from '@/libs/mixins/index'
import store from '@/store'
// import router from '@/router'

export const useDepartmentSetting = () => {
  const {
    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  } = useCommenSettings()

  const ui = {}

  return {
    ui,

    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  }
}

export const useDepartmentList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    refetchData,
  } = useTableComponent()

  const {
    useHttpCodeAlert,
  } = useAlert()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '部門名稱', key: 'name', sortable: true, searchable: true, select: true,
    },
    {
      label: '說明', key: 'description', sortable: true, searchable: true, select: true,
    },
    {
      label: '員工數量', key: 'total', sortable: true, searchable: true, select: true,
    },
    {
      label: '創建日期', key: 'created_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const timeRange = ref(null)

  const setDepartmentDelete = (...arg) => store.dispatch('admin-department/setDepartmentDelete', ...arg)
  const setDepartmentCreate = (...arg) => store.dispatch('admin-department/setDepartmentCreate', ...arg)
  const setDepartmentUpdate = (...arg) => store.dispatch('admin-department/setDepartmentUpdate', ...arg)

  const getDepartmentListData = (ctx, callback) => {
    // if (store.state.app.ability.user === 'none') {
    //   router.replace({ name: 'admin-home' })
    //   return
    // }

    // 整理timeRange
    // let resolveTimeRange = null
    // if (timeRange.value) {
    //   resolveTimeRange = timeRange.value.split(' 至 ')
    //   const [start, end] = resolveTimeRange
    //   if (end) {
    //     resolveTimeRange = `${start} to ${end}`
    //   } else resolveTimeRange = `${start}`
    // }

    store.dispatch('admin-department/getDepartmentList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      // range: resolveTimeRange,
      // filters: resolveFilters,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data
        callback(data)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, timeRange], () => {
    refetchData()
  })

  return {
    isBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
    refetchTable,

    setDepartmentDelete,
    setDepartmentCreate,
    setDepartmentUpdate,
    getDepartmentListData,
    useHttpCodeAlert,
  }
}
